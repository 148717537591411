<template>
  <modal v-model="showModal" dimmer maskClose :autoClose="autoClose" :duration="duration">
    <div class="tool-tip-panel" @click="handleClick">
      <span v-if="tipType==='warning'" class="waring fa fa-warning"></span>
      <span v-if="tipType==='success'" class="success fa fa-check"></span>
      <span class="text">{{ text }}</span>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    tipType: {
      type: String,
      default: 'warning' // success
    },
    duration: {
      type: Number,
      default: 1500
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    handleClick () {
      //
    }
  }
}
</script>
<style lang="less">
.tool-tip-panel {
  height: 80px;
  line-height: 80px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: #fff;
  border-radius: 4px;
  .waring {
    color: rgb(255, 209, 58);
    margin-right: 0.5rem;
  }
  .success {
    color: #14c358;
    font-size: 18px;
    margin-right: 0.5rem;
  }
}
</style>
