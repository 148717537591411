<template>
  <modal v-model="showModal" dimmer light maskClose>
    <div class="login-tip" @click="handleLogin">
      <span class="waring fa fa-warning"></span>
      <span class="text" style="font-size:16px;">点击登录 !<br></span>
      <span><a style="font-size:12px;">{{text}}</a></span>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '微信扫码登录后可查看'
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    handleLogin () {
      this.$parent.login()
    }
  }
}
</script>
<style lang="less">
.login-tip {
  padding: 20px 60px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  .waring {
    color: rgb(255, 209, 58);
    margin-right: 0.5rem;
    font-size: 16px;
  }
}
</style>
