<template>
  <div class="cu-line-wrapper">
    <svg :viewBox="viwBox" :width="width" :height="height" @mousemove="handleMouseMove"
     xmlns="http://www.w3.org/2000/svg">
      <g v-if="title">
        <text class="cu-bar-title" :x="width/2" :y="titleY" :style="titleStyle" text-anchor="middle">{{title}}</text>
      </g>
      <g class="cu-grid" v-if="showGridLine">
        <path class="cu-grid-line" v-for="(item, index) in ticksY" :key="index" :d="`M ${grid.ltx} ${item.my} L ${grid.rtx} ${item.ly}`"></path>
      </g>
      <g class="cu-bar-group">
        <rect class="cu-bar-bg" v-for="(item, index) in barGroup" :key="index" :x="item.x" :y="item.y" :width="item.width" :height="item.height"></rect>
      </g>
      <g class="cu-axis-x" v-if="showXAxis">
        <path class="cu-axis" :d="`M ${grid.lbx} ${grid.lby} L ${grid.rbx} ${grid.rby}`"></path>
      </g>
      <g class="cu-axis-y" v-if="showYAxis">
        <path class="cu-axis" :d="`M ${grid.ltx} ${grid.lty} L ${grid.lbx} ${grid.lby}`"></path>
      </g>
      <g class="cu-ticks-x" v-if="showXAxisTick">
        <path class="cu-tick" v-for="(item, index) in ticks" :key="index" :d="`M ${item.mx} ${item.my} L ${item.lx} ${item.ly}`"></path>
      </g>
      <g class="cu-ticks-y" v-if="showYAxisTick">
        <path class="cu-tick" v-for="(item, index) in ticksY" :key="index" :d="`M ${item.mx} ${item.my} L ${item.lx} ${item.ly}`"></path>
      </g>
      <g class="cu-labels-x" v-if="showXLabel">
        <text class="cu-label-axis" v-for="(item, index) in labelX" :key="index" text-anchor="middle" :x="item.x" :y="item.y">{{item.label}}</text>
      </g>
      <g class="cu-labels-y" v-if="showYLabel">
        <text class="cu-label-axis" v-for="(item, index) in ticksY" :key="index" text-anchor="end" :x="item.lx-3" :y="item.ly+4">{{item.label}}</text>
      </g>
      <g class="cu-line-group">
        <path class="cu-line" :stroke="lineColors[0]" v-for="(item, index) in lines" :key="index" :d="`M ${item.mx} ${item.my} L ${item.lx} ${item.ly}`"></path>
      </g>
      <g class="cu-point-group">
        <path class="cu-point" :stroke="lineColors[0]" v-for="(item, index) in points" :key="index" :d="`M ${item.mx+4} ${item.my} A 4 4 0 1 0 ${item.mx+4} ${item.my+0.004}`"></path>
      </g>
      <g class="cu-value-group" v-if="hintPos==='fixed'">
        <text class="cu-line-value" v-for="(item, index) in points" :key="index" :x="item.mx" :y="item.my-10" text-anchor="middle">{{item.value}}</text>
      </g>
      <g>
        <path class="cu-hint-line" :opacity="showHintVerticalLine?1:0" :d="`M ${hintVerticalLineLeft} ${marginTop} L ${hintVerticalLineLeft} ${grid.lby}`"></path>
      </g>
    </svg>
    <div v-if="hintPos==='auto'" class="hint-auto" :style="{left: hintLeftAuto+'px', top: hintTopAuto+'px', display: showHintAuto?'block':'none'}"
      @mouseenter="handleMouseEnterHintTop">
      <span>{{currentName}}:</span>
      <span>{{currentValue}}</span>
    </div>
    <div v-if="hintPos==='top'" class="hint-top" :style="{left: hintLeft+'px', top: hintTop+'px', display: showHintTop?'block':'none'}"
      @mouseenter="handleMouseEnterHintTop"
      @mouseleave="handleMouseLeaveHintTop">
      <span>{{currentName}}:</span>
      <span>{{currentValue}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 200
    },
    height: {
      type: [Number, String],
      default: 100
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    chartTitle: String,
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      title: '',
      titleY: 0,
      titleStyle: {},

      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,

      lineBoundaryGap: 0,  // 折线在grid中左右的padding
      lineColors: ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],

      hintPos: 'auto',
      currentName: '',
      currentValue: '',
      hintLeft: 0,
      hintTop: 0,
      hintLeftAuto: 0,
      hintTopAuto: 0,
      showHintTop: false,
      showHintBottom: false,
      showHintAuto: false,
      hintPosDis: 15,
      hintVerticalLineLeft: 0,
      showHintVerticalLine: false,

      showXLabel: false,
      showXAxis: false,
      showXAxisTick: false,
      xlabelRotate: false,

      showYLabel: false,
      showYAxis: false,
      showYAxisTick: false,
      ylabelRotate: false,

      showGridLine: false
    }
  },
  computed: {
    viwBox () {
      return `0 0 ${this.width} ${this.height}`
    },
    grid () {
      let gd = {}
      gd.ltx = this.marginLeft
      gd.lty = this.marginTop
      gd.lbx = this.marginLeft
      gd.lby = this.height - this.marginBottom
      gd.rtx = this.width - this.marginRight
      gd.rty = this.marginTop
      gd.rbx = this.width - this.marginRight
      gd.rby = this.height - this.marginBottom
      gd.width = this.width - this.marginLeft - this.marginRight
      gd.height = this.height - this.marginTop - this.marginBottom
      if (gd.rbx <= 0 || gd.rby <= 0 || gd.width <= 0 || gd.height <= 0) {
        throw new Error('margin is bigger than gird\'s width or height')
      }
      return gd
    },
    barGroup () {
      if (!this.data) return []
      let barList = []
      // 柱条最大值
      let barMaxVal = 0
      // 柱条个数
      let barNum = this.data.length || 1
      // 柱条最大值
      this.data.forEach(item => {
        if (item.value > barMaxVal) barMaxVal = item.value
      })
      barMaxVal = this.getBarMaxValue(barMaxVal)
      // chart grid 实际区域高宽
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let gridHeight = this.height - this.marginBottom - this.marginTop
      if (gridWidth <= 0 || gridHeight <= 0) {
        throw new Error('margin is bigger than gird\'s width or height')
      }
      // 柱条宽度
      let barWidth = (gridWidth - 2 * this.lineBoundaryGap) / (barNum - 1 || 1)
      this.data.forEach((item, index) => {
        let bar = {
          name: item.name,
          value: item.value,
          x: this.marginLeft + (index === 0 ? 0 : this.lineBoundaryGap + (index - 0.5) * barWidth),
          y: this.marginTop,
          width: (index === 0 || index === this.data.length - 1) ? this.lineBoundaryGap + 0.5 * barWidth : barWidth,
          height: gridHeight
        }
        barList.push(bar)
      })
      return barList
    },
    lines () {
      let lineList = []
      let barNum = this.data.length || 1
      // 折线最大值
      let lineMaxVal = 0
      this.data.forEach(item => {
        if (item.value > lineMaxVal) lineMaxVal = item.value
      })
      lineMaxVal = this.getBarMaxValue(lineMaxVal)
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let gridHeight = this.height - this.marginBottom - this.marginTop
      let barWidth = (gridWidth - 2 * this.lineBoundaryGap) / (barNum - 1 || 1)
      for (let i = 0; i < this.data.length - 1; i++) {
        let start = this.data[i]
        let end = this.data[i + 1]
        let line = {
          mx: this.lineBoundaryGap + i * barWidth + this.marginLeft,
          my: (start.value / lineMaxVal) * gridHeight,
          lx: this.lineBoundaryGap + (i + 1) * barWidth + this.marginLeft,
          ly: (end.value / lineMaxVal) * gridHeight
        }
        lineList.push(line)
      }
      // line.y 平移距离为 gridHeight - line.y，即中空距离
      lineList.forEach(line => {
        line.my = this.marginTop + (gridHeight - line.my)
        line.ly = this.marginTop + (gridHeight - line.ly)
      })
      return lineList
    },
    points () {
      let pointList = []
      let barNum = this.data.length || 1
      // 折线最大值
      let lineMaxVal = 0
      this.data.forEach(item => {
        if (item.value > lineMaxVal) lineMaxVal = item.value
      })
      lineMaxVal = this.getBarMaxValue(lineMaxVal)
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let gridHeight = this.height - this.marginBottom - this.marginTop
      let barWidth = (gridWidth - 2 * this.lineBoundaryGap) / (barNum - 1 || 1)
      for (let i = 0; i < this.data.length; i++) {
        let item = this.data[i]
        let point = {
          mx: this.lineBoundaryGap + i * barWidth + this.marginLeft,
          my: (item.value / lineMaxVal) * gridHeight,
          value: item.value
        }
        pointList.push(point)
      }
      // point.y 平移距离为 gridHeight - point.y，即中空距离
      pointList.forEach(point => {
        point.my = this.marginTop + (gridHeight - point.my)
      })
      return pointList
    },
    ticks () {
      let tickList = []
      let barNum = this.data.length || 1
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let barWidth = (gridWidth - 2 * this.lineBoundaryGap) / (barNum - 1 || 1)
      let gdBottom = this.height - this.marginBottom
      if (gridWidth <= 0) {
        throw new Error('margin is bigger than gird\'s width')
      }
      for (let i = 0; i < this.data.length; i++) {
        tickList.push({
          mx: this.lineBoundaryGap + i * barWidth + this.marginLeft,
          my: gdBottom,
          lx: this.lineBoundaryGap + i * barWidth + this.marginLeft,
          ly: gdBottom + 5
        })
      }
      return tickList
    },
    ticksY () {
      let tickList = []
      // 柱条最大值
      let barMaxVal = 0
      this.data.forEach(item => {
        if (item.value > barMaxVal) barMaxVal = item.value
      })
      barMaxVal = this.getBarMaxValue(barMaxVal)
      let intervalValue = barMaxVal / 5
      let gridHeight = this.height - this.marginBottom - this.marginTop
      let intervalHeight = gridHeight / 5
      if (gridHeight <= 0) {
        throw new Error('margin is bigger than gird\'s height')
      }
      if (this.data.length) {
        for (let i = 0; i < 6; i++) {
          tickList.push({
            mx: this.marginLeft,
            my: i * intervalHeight + this.marginTop,
            lx: this.marginLeft - 5,
            ly: i * intervalHeight + this.marginTop,
            label: (5 - i) * intervalValue
          })
        }
      }
      return tickList
    },
    labelX () {
      let labels = []
      let barNum = this.data.length || 1
      // chart grid 实际区域高宽
      let gridWidth = this.width - this.marginRight - this.marginLeft
      // 柱条宽度
      let barWidth = (gridWidth - 2 * this.lineBoundaryGap) / (barNum - 1 || 1)
      let gdBottom = this.height - this.marginBottom
      if (gridWidth <= 0) {
        throw new Error('margin is bigger than gird\'s width')
      }
      this.data.forEach((item, index) => {
        let label = {
          x: this.lineBoundaryGap + index * barWidth + this.marginLeft,
          y: gdBottom + 20,
          label: item.name
        }
        labels.push(label)
      })
      return labels
    }
  },
  watch: {
    width (val) {
      if (val) {
        this.updateLineBoundaryGap()
        this.updateHintAutoPos()
      }
    },
    height (val) {
      if (val) { this.updateHintAutoPos() }
    },
    options (val) {
      if (val) { this.initOptions() }
    },
    chartTitle (val) {
      if (val) {
        this.title = val
      }
    },
    data (val) {
      if (val) { this.updateLineBoundaryGap() }
    }
  },
  created () {
    this.initOptions()
    this.updateLineBoundaryGap()
  },
  methods: {
    initOptions () {
      if (this.options.title) {
        this.title = this.options.title.text || ''
        this.titleY = this.options.title.top || 14
        this.titleStyle = this.options.title.style || {}
      }
      if (this.options.margin) {
        this.marginTop = this.options.margin.top || 0
        this.marginRight = this.options.margin.right || 0
        this.marginBottom = this.options.margin.bottom || 0
        this.marginLeft = this.options.margin.left || 0
      }
      if (this.options.x) {
        this.showXLabel = !!this.options.x.label
        this.showXAxis = !!this.options.x.axis
        this.showXAxisTick = !!this.options.x.tick
        this.xlabelRotate = !!this.options.x.labelRotate
      }
      if (this.options.y) {
        this.showYLabel = !!this.options.y.label
        this.showYAxis = !!this.options.y.axis
        this.showYAxisTick = !!this.options.y.tick
        this.ylabelRotate = !!this.options.y.labelRotate
      }
      if (this.options.grid) {
        this.showGridLine = !!this.options.grid.line
      }
      if (this.options.line) {
        if (this.options.line.colors && this.options.line.colors.length) {
          this.lineColors = this.options.line.colors
        }
      }
      if (this.options.hint) {
        this.hintPos = this.options.hint.pos || 'auto'
        this.updateHintAutoPos()
      }
    },
    handleMouseEnterHintTop () {
      this.showHint()
    },
    handleMouseLeaveHintTop () {
      this.hiddenHint()
    },
    handleMouseMove (e) {
      let x = e.offsetX
      let y = e.offsetY
      if (this.hintPos === 'auto') {
        let hint = false
        for (let i = this.barGroup.length - 1; i >= 0; i--) {
          let item = this.barGroup[i]
          let point = this.points[i]
          if (x >= item.x && x <= item.x + item.width &&
              y >= item.y && y <= item.y + item.height) {
            this.hintVerticalLineLeft = point.mx
            this.currentName = item.name
            this.currentValue = item.value
            this.showHint()
            let charWidth = 15 * this.currentName.length + 7 * (this.currentValue.length + 1) + 16
            let charHeight = 16 + 10
            let $hintAuto = this.$el.querySelector('.hint-auto')
            let hintWidth = ($hintAuto && $hintAuto.offsetWidth) || charWidth
            let hintHeight = ($hintAuto && $hintAuto.offsetHeight) || charHeight
            if (y + this.hintPosDis > this.height - this.marginBottom && x + this.hintPosDis > this.width - this.marginRight) {
              // 超过右下，移到左上角
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x - this.hintPosDis - hintWidth, y - this.hintPosDis - hintHeight)
            } else if (y + this.hintPosDis > this.height - this.marginBottom) {
              // 超过下边，移到右上角
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x + this.hintPosDis, y - this.hintPosDis - hintHeight)
            } else if (x + this.hintPosDis > this.width - this.marginRight) {
              // 超过右边，移到左下角
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x - this.hintPosDis - hintWidth, y + this.hintPosDis)
            } else {
              this.moveHint(this.hintLeftAuto, this.hintTopAuto, x + this.hintPosDis, y + this.hintPosDis)
            }
            hint = true
            break
          }
        }
        if (!hint) {
          this.hiddenHint()
        }
      } else {
        // hintPos === 'top' | 'bottom'
        if (x <= this.marginLeft || x >= this.width - this.marginRight ||
            y <= this.marginTop || y >= this.height - this.marginBottom) {
          this.hiddenHint()
        } else {
          for (let i = this.barGroup.length - 1; i >= 0; i--) {
            let item = this.barGroup[i]
            let point = this.points[i]
            if (x > item.x && x < item.x + item.width) {
              this.hintVerticalLineLeft = point.mx
              this.hintLeft = point.mx
              this.hintTop = point.my
              this.currentName = item.name
              this.currentValue = item.value
              this.showHint()
              break
            }
          }
        }
      }
    },
    showHint () {
      if (this.hintPos === 'top') {
        this.showHintTop = true
        this.showHintVerticalLine = true
      } else if (this.hintPos === 'auto') {
        this.showHintAuto = true
        this.showHintVerticalLine = true
      }
    },
    hiddenHint () {
      if (this.hintPos === 'top') {
        this.showHintTop = false
        this.showHintVerticalLine = false
      } else if (this.hintPos === 'auto') {
        this.showHintAuto = false
        this.showHintVerticalLine = false
      }
    },
    updateHintAutoPos () {
      this.hintLeftAuto = this.width / 2
      this.hintTopAuto = this.height / 2
      this.updateHintPosDis()
    },
    updateHintPosDis () {
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let gridHeight = this.height - this.marginBottom - this.marginTop
      if (gridWidth < 50 || gridHeight < 50) {
        this.hintPosDis = 10
      } else if (gridWidth < 100 || gridHeight < 100) {
        this.hintPosDis = 15
      } else {
        this.hintPosDis = 20
      }
    },
    updateLineBoundaryGap () {
      if (this.options.line) {
        let gap = this.options.line.boundaryGap
        if (typeof gap === 'string' && gap.length) {
          if (gap[gap.length - 1] === '%') {
            gap = gap.slice(0, gap.length - 1)
            gap = Number(gap) / 100
            this.lineBoundaryGap = gap * (this.width - this.marginLeft - this.marginRight)
          } else {
            this.lineBoundaryGap = Number(gap) || 0
          }
        } else if (typeof gap === 'number') {
          this.lineBoundaryGap = gap
        }
      }
    },
    moveHint (x1, y1, x2, y2) {
      let requestAnimationFrame = window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
                                  window.webkitRequestAnimationFrame || window.msRequestAnimationFrame
      let len = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
      let skip = 1
      if (len < 50) {
        skip = 2
      } else if (len < 100) {
        skip = 4
      } else if (len < 200) {
        skip = 6
      } else if (len < 400) {
        skip = 8
      } else if (len < 800) {
        skip = 10
      } else if (len < 1600) {
        skip = 12
      } else {
        skip = 14
      }
      let cnt = 0
      if (!len) return

      // console.log('--------------')
      let step = () => {
        cnt += 1
        if (cnt > 0) {
          let dis = 1
          if (skip <= 6) {
            dis = this.cubicEaseInOut(cnt, 1, 1, skip) - 1
          } else {
            dis = this.quadEaseOut(cnt, 1, 1, skip) - 1
          }
          let dx = x1 + (len * dis) * (x2 - x1) / len
          let dy = y1 + (len * dis) * (y2 - y1) / len
          // console.log('skip', cnt, skip, dx.toFixed(2))
          if (skip < 4) {
            this.hintLeftAuto = dx
            this.hintTopAuto = dy
          } else {
            if ((x2 > this.hintLeftAuto && dx > this.hintLeftAuto) || (x2 < this.hintLeftAuto && dx < this.hintLeftAuto)) {
              this.hintLeftAuto = dx
            }
            if ((y2 > this.hintTopAuto && dy > this.hintTopAuto) || (y2 < this.hintTopAuto && dy < this.hintTopAuto)) {
              this.hintTopAuto = dy
            }
          }
        }
        if (cnt < skip) {
          requestAnimationFrame(step)
        }
      }
      requestAnimationFrame(step)
    },
    getBarMaxValue (val) {
      let maxVal = 0
      let times = 5
      if (val >= 1 && val <= 10) {
        maxVal = this.getMultiCeil(val, 2)
      } else if (val > 10) {
        times = this.getTimes(val)
        maxVal = this.getMultiCeil(val, times)
      }
      return maxVal
    },
    getMultiCeil (val, times) {
      let maxVal = Math.ceil(val)
      // 取 times 的整数倍 (2, 5, 10, 50, 100)
      let multi = Math.ceil(maxVal / times)
      maxVal = times * multi
      return maxVal
    },
    getTimes (val) {
      let temp = val
      let cnt = 0
      let i = 0
      let re = 1
      // 12/10=1.2   50   5*1=5
      // 61/10=6.1   100  10*1=10
      //
      // 450/10=45    45/10=4.5    500   5*10=50
      // 809/10=80.9  80.9/10=8.9  1000  10*10=100
      while (temp > 10) {
        temp /= 10
        cnt += 1
      }
      if (temp <= 5) {
        i = 0
        re = 5
        while (i < cnt - 1) {
          re *= 10
          i += 1
        }
      } else if (temp <= 10) {
        i = 0
        re = 10
        while (i < cnt - 1) {
          re *= 10
          i += 1
        }
      }
      return re
    },
    lightenDarkenColor (col, amt) {
      // Lighten var NewColor = lightenDarkenColor('#F06D06', 20)
      // Darken var NewColor = lightenDarkenColor('#F06D06', -20)
      var usePound = false
      if (col[0] === '#') {
        col = col.slice(1)
        usePound = true
      }
      if (col.length === 8) {
        // 处理透明
        var rgb = col.slice(0, 6)
        var a = col.slice(6, 8)
        a = parseInt(a, 16)
        a -= amt * 2
        a = Number(a).toString(16)
        return '#' + rgb + a
      }
      var num = parseInt(col, 16)
      var r = (num >> 16) + amt
      if (r > 255) {
        r = 255
      } else if (r < 0) {
        r = 0
      }
      var b = ((num >> 8) & 0x00FF) + amt
      if (b > 255) {
        b = 255
      } else if (b < 0) {
        b = 0
      }
      var g = (num & 0x0000FF) + amt
      if (g > 255) {
        g = 255
      } else if (g < 0) {
        g = 0
      }
      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    },
    quadEaseOut: function (t, b, c, d) {
      return -c * (t /= d) * (t - 2) + b
    },
    cubicEaseInOut (t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t * t + b
      return c / 2 * ((t -= 2) * t * t + 2) + b
    }
  }
}
</script>
<style>
.cu-line-wrapper .hint-top {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(33,33,33,0.8);
  color: #fff;
  padding: 0.4rem 0.6rem;
  font-size: inherit;
  white-space: nowrap;
  text-align: center;
  border-radius: 2px;
  transform: translate(-50%, -130%);
  display: none;
  top: 0;
  transition: left ease-in-out 0.2s;
}
.cu-line-wrapper .hint-top:before {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border-top: 0.4rem solid rgba(33,33,33,0.8);
  border-right: 0.4rem solid transparent;
  border-bottom: 0.4rem solid transparent;
  border-left: 0.4rem solid transparent;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 95%);
}
</style>
