import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  data () {
    return {
      ifList: [],
      showIfList: false,
      showJFullTitle: false,
      chartPos: {},
      chartTitle: '',

      optionsIfList: {
        title: {
          text: '影响因子变化',
          top: 25,
          style: {
            'font-size': '14px',
            'font-weight': 600
          }
        },
        margin: {
          top: 40,
          right: 15,
          bottom: 40,
          left: 15
        },
        line: {
          boundaryGap: '5%',  // 20%, 0 10 两边轴内总空格
          colors: []
        },
        hint: {
          pos: 'fixed'  // 'auto', 'top', 'fixed'
        },
        x: {
          label: true,
          axis: true,
          tick: true
        },
        y: {
          label: false,
          axis: false,
          tick: false
        },
        grid: {
          line: true
        }
      }
    }
  },
  methods: {
    getIfList (pmidIssnList, callback) {
      this.$http.post(`${this.httpRoot}/paper/iflist/map`, {pmidIssnList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.iflistMap || {})
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onJournalMouseEnter (ifList, rect, journalTitle) {
      this.ifList = this.regData(ifList || [])
      if (journalTitle) {
        this.chartTitle = journalTitle
      }
      if (this.ifList.length) {
        this.chartPos = {top: rect.top + 'px', left: rect.left + 'px'}
        this.showIfList = true
      } else {
        this.chartPos = {top: rect.top + 'px', left: rect.left + 'px'}
        this.showJFullTitle = true
      }
    },
    onJournalMouseLeave () {
      this.showIfList = false
      this.showJFullTitle = false
    },
    regData (list) {
      return list.map(item => {
        return {
          name: item.key || item.name,
          value: Number(item.value)
        }
      })
    }
  }
}
